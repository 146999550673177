<template>
  <div class="shopList">
    <!-- <el-header class="box add"> -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>文档管理</el-breadcrumb-item>
          <el-breadcrumb-item>文档列表</el-breadcrumb-item>
        </el-breadcrumb> -->
    <div style="padding: 20px">
      <div
        class="df aic"
        style="
          padding: 20px;
          justify-content: space-between;
          background-color: #fff;
          border: 1px solid #ccc;
        "
      >
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>文档管理</el-breadcrumb-item>
          <el-breadcrumb-item>文档列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div>
          <el-switch
            v-model="isShare"
            active-text="共享资讯"
            inactive-text="本企业资讯"
            @change="switchChange"
          >
          </el-switch>
        </div>
      </div>
    </div>
    <!-- </el-header> -->
    <!-- <el-main> -->
    <div
      style="padding: 0 20px; width: 100%"
      class="df aic jcb"
      v-if="!isShare"
    >
      <el-form
        :inline="true"
        :model="query"
        class="demo-form-inline"
        style="flex: 1"
      >
        <el-col :span="4" class="mr10">
          <el-form-item size="medium">
            <el-input
              placeholder="请输入文章标题、文章内容查询"
              v-model="query.keyword"
              class="input-with-select"
              clearable
              @clear="search"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10" class="mr10">
          <el-form-item size="medium">
            <el-input class="input-with-select" disabled>
              <div slot="prefix" class="df aic jcc" style="height: 100%">
                <el-tag
                  closable
                  size="mini"
                  @close="delTag(item)"
                  style="margin-right: 10px"
                  type="info"
                  v-for="item in tagKeyword"
                  :key="item.id"
                  >{{ item.tagName }}</el-tag
                >
              </div>
              <div slot="suffix" class="df aic jcc" style="height: 100%">
                <el-dropdown @command="addTag" placement="bottom">
                  <el-button type="primary" size="mini"> 添加标签 </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <template v-if="tagComputed.length">
                      <el-dropdown-item
                        v-for="item in tagComputed"
                        :key="item.id"
                        :command="item"
                        >{{ item.tagName }}</el-dropdown-item
                      >
                    </template>
                    <el-dropdown-item disabled v-else
                      >暂无更多标签</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <span></span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="tl mr10">
          <el-form-item size="medium">
            <el-button type="primary" size="medium" @click="search"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-form>
      <div style="padding-bottom: 22px">
        <el-button type="success" @click="gotoCreate" size="medium"
          >编写文章</el-button
        >
        <el-button type="primary" size="medium" @click="showRecommend = true"
          >推荐管理</el-button
        >
        <el-button
          type="warning"
          size="medium"
          @click="
            dialogVisible = true;
            tagSearch();
          "
          >标签管理</el-button
        >
        <el-button
          size="medium"
          type="success"
          @click="
            showShare = true;
            getShare();
          "
          >分享记录</el-button
        >
      </div>
    </div>
    <div v-else class="df jcb">
      <el-form
        :inline="true"
        :model="query"
        class="demo-form-inline"
        style="flex: 1"
      >
        <el-col :span="4" class="mr10">
          <el-form-item style="width: 100%; padding-left: 20px">
            <el-autocomplete
              placeholder="资讯来源"
              v-model="query.keyword"
              :fetch-suggestions="querySearchAsync"
              clearable
              @select="handleSelect1"
              @clear="
                query.keyword = '';
                search();
              "
            >
              <template slot-scope="{ item }">
                <span class="name">{{ item.company_name }}</span>
                <span
                  class="addr"
                  style="font-size: 12px; color: silver; float: right"
                  >{{ item.credit_code }}</span
                >
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="2" class="mr10">
          <el-select
            v-model="shareStatus"
            placeholder="请选择"
            @change="optionChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6" class="tl mr10">
          <el-form-item size="medium">
            <el-button style="margin-top: 2px" type="primary" @click="search"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-form>
      <div style="padding-bottom: 22px; padding-right: 20px">
        <el-button
          type="primary"
          @click="
            showShareCompany = true;
            getshareCompany();
          "
          >分享企业</el-button
        >
      </div>
    </div>
    <div style="padding: 20px; padding-top: 0px" v-if="!isShare">
      <el-table border :data="list" style="width: 100%" v-loading="isLoading">
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="100">
        </el-table-column>
        <el-table-column prop="digest" label="摘要" width="120">
        </el-table-column>
        <el-table-column prop="coverImg" label="封面" width="200">
          <template slot-scope="scope">
            <el-image
              fit="cover"
              class="avatar"
              :preview-src-list="[scope.row.coverImg]"
              v-if="scope.row.coverImg"
              :src="scope.row.coverImg"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="types" label="标签" width="120">
          <template slot-scope="scope">
            <el-tag
              style="margin-right: 10px; margin-bottom: 10px"
              type="info"
              v-for="(item, index) in scope.row.types"
              :key="index"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib"
              size="mini"
              @click="handleEdit(scope.row)"
              >修改</el-button
            >
            <el-popover
              placement="left"
              width="400"
              trigger="click"
              v-model="scope.row.isShare"
              @hide="
                formInline.shareCompanyId = null;
                formInline.shareCompanyName = '';
              "
            >
              <div style="padding: 20px">
                <el-form
                  :inline="true"
                  ref="shareForm"
                  :model="formInline"
                  :rules="shareRule"
                  class="demo-form-inline"
                >
                  <el-form-item prop="shareCompanyName">
                    <el-autocomplete
                      placeholder="企业名称"
                      v-model="formInline.shareCompanyName"
                      :fetch-suggestions="querySearchAsync"
                      clearable
                      @select="handleSelect"
                    >
                      <template slot-scope="{ item }">
                        <span class="name">{{ item.company_name }}</span>
                        <span
                          class="addr"
                          style="font-size: 12px; color: silver; float: right"
                          >{{ item.credit_code }}</span
                        >
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="handleShare(scope.row.id, 1, scope.row)"
                      >分享</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <el-button
                style="margin-left: 10px"
                type="text"
                class="disib orange"
                size="mini"
                slot="reference"
                >分享</el-button
              >
            </el-popover>
            <el-button
              type="text"
              class="disib red"
              size="mini"
              style="margin-left: 10px"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      style="padding: 20px; padding-top: 0px"
      v-if="isShare && (!total || list.length)"
    >
      <el-table
        border
        :data="list"
        style="width: 100%"
        v-loading="isLoading"
        ref="shareTable"
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>

        <el-table-column prop="sharedCompanyName" label="资讯来源" width="100">
        </el-table-column>

        <el-table-column label="分享内容" width="180">
          <template slot-scope="scope">
            <div v-if="scope.row.type == 1">
              {{ "《" + scope.row.sharedName + "》" }}
            </div>
            <div v-else>
              <el-tag type="info">{{ scope.row.sharedName }}</el-tag>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column label="分享类型" width="55">
          <template #default="scope">
            {{ ["标签", "资讯"][scope.row.type] }}
          </template>
        </el-table-column> -->

        <el-table-column prop="shareUserName" label="分享人" width="80">
        </el-table-column>

        <el-table-column prop="sharedTime" label="分享时间" width="100">
          <template slot-scope="scope">
            {{ scope.row.sharedTime.replace("T", " ") }}
          </template>
        </el-table-column>

        <el-table-column width="140" label="审核状态">
          <template slot-scope="scope">
            <div v-if="!scope.row.isAudit" class="green">无需审核</div>
            <div
              v-else-if="!scope.row.isDel"
              :class="passStatus[scope.row.isPass].class"
            >
              {{ passStatus[scope.row.isPass].text }}
            </div>
            <div v-if="scope.row.isDel" style="color: #bbb">已屏蔽</div>
          </template>
        </el-table-column>
        <el-table-column label="预览" width="50">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.type == 1"
              type="text"
              class="disib"
              size="mini"
              @click="handlePreview(scope.row, 0)"
              >预览</el-button
            >
            <el-button
              v-else
              type="text"
              class="disib"
              size="mini"
              @click="handlePreview(scope.row, 1)"
              >标签详情</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="审核操作" width="180">
          <template slot-scope="scope">
            <span v-if="!scope.row.isDel">
              <span v-if="scope.row.isAudit && scope.row.isPass === 0">
                <el-button
                  type="text"
                  class="disib green"
                  size="mini"
                  @click="handleAudit(scope.row, 1)"
                  >通过</el-button
                >
                <el-button
                  type="text"
                  class="disib red"
                  size="mini"
                  @click="handleAudit(scope.row, 2)"
                  >不通过</el-button
                >
              </span>
              <span v-if="scope.row.isAudit && scope.row.isPass === 1">
                <el-button
                  type="text"
                  class="disib red"
                  size="mini"
                  @click="handleAudit(scope.row, 2)"
                  >不通过</el-button
                >
              </span>
              <span v-if="scope.row.isAudit && scope.row.isPass === 2">
                <el-button
                  type="text"
                  class="disib green"
                  size="mini"
                  @click="handleAudit(scope.row, 1)"
                  >通过</el-button
                >
              </span>
            </span>
            <el-button
              type="text"
              class="disib"
              size="mini"
              style="margin-left: 10px; color: #bbb !important"
              v-if="!scope.row.isDel"
              @click="handleAudit(scope.row, true)"
              >屏蔽{{ scope.row.type === 1 ? "资讯" : "标签" }}</el-button
            >
            <el-button
              type="text"
              class="disib"
              size="mini"
              style="margin-left: 10px; color: #bbb !important"
              v-else
              @click="handleAudit(scope.row, false)"
              >取消屏蔽</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="df" style="justify-content: flex-end">
      <el-pagination
        background
        :page-size="10"
        layout="total,prev, pager, next"
        :total="total"
        :current-page="query.page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
    <!-- </el-main> -->
    <el-dialog
      title="标签管理"
      :visible.sync="dialogVisible"
      width="50%"
      @close="handleClose"
    >
      <div>
        <div>
          <el-form :inline="true" :model="query" class="demo-form-inline">
            <el-col :span="8" class="mr10">
              <el-form-item size="medium">
                <el-input
                  placeholder="请输入标签名称查询"
                  v-model="query.keyword"
                  class="input-with-select"
                  clearable
                  @clear="tagSearch"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="tl mr10">
              <el-form-item size="medium">
                <el-button
                  type="primary"
                  @click="tagSearch"
                  class="general_bgc general_border cfff"
                  >查询</el-button
                >
              </el-form-item>
            </el-col>
            <el-col :span="6" class="tl mr10">
              <AddTag style="margin-left: 10px" @addFinish="addFinish" />
            </el-col>
          </el-form>
        </div>

        <div>
          <el-form
            ref="ruleForm"
            :model="tagList[chooseIndex]"
            label-width="0px"
            :rules="rules"
          >
            <el-table border :data="tagList" style="width: 100%">
              <el-table-column type="index" label="序号" width="55">
              </el-table-column>
              <el-table-column prop="tagName" label="标签名称" width="120">
                <template slot-scope="scope">
                  <div v-if="!scope.row.isEdit">{{ scope.row.tagName }}</div>
                  <div v-else>
                    <el-form-item prop="tagName">
                      <el-input v-model="scope.row.tagName" :maxlength="20">
                        <span slot="suffix" style="margin-right: 10px"
                          >{{ scope.row.tagName.length }}/20</span
                        >
                      </el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="tagDesc" label="标签描述" width="120">
                <template slot-scope="scope">
                  <div v-if="!scope.row.isEdit">{{ scope.row.tagDesc }}</div>
                  <div v-else>
                    <el-form-item prop="tagDesc">
                      <el-input
                        type="textarea"
                        v-model="scope.row.tagDesc"
                      ></el-input>
                    </el-form-item>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    class="disib"
                    size="mini"
                    @click="
                      scope.row.isEdit = true;
                      chooseIndex = scope.$index;
                    "
                    v-if="!scope.row.isEdit"
                    >修改</el-button
                  >
                  <el-button
                    type="text"
                    class="disib green"
                    size="mini"
                    @click="handleTagEdit(scope.row)"
                    v-else
                    >完成</el-button
                  >
                  <el-popover
                    placement="left"
                    width="400"
                    trigger="click"
                    v-model="scope.row.isShare"
                    @hide="
                      formInline.shareCompanyId = null;
                      formInline.shareCompanyName = '';
                    "
                  >
                    <div style="padding: 20px">
                      <el-form
                        :inline="true"
                        ref="shareForm"
                        :model="formInline"
                        :rules="shareRule"
                        class="demo-form-inline"
                      >
                        <el-form-item prop="shareCompanyName">
                          <el-autocomplete
                            placeholder="企业名称"
                            v-model="formInline.shareCompanyName"
                            :fetch-suggestions="querySearchAsync"
                            clearable
                            @select="handleSelect"
                          >
                            <template slot-scope="{ item }">
                              <span class="name">{{ item.company_name }}</span>
                              <span
                                class="addr"
                                style="
                                  font-size: 12px;
                                  color: silver;
                                  float: right;
                                "
                                >{{ item.credit_code }}</span
                              >
                            </template>
                          </el-autocomplete>
                        </el-form-item>
                        <el-form-item>
                          <el-button
                            type="primary"
                            @click="handleShare(scope.row.id, 0, scope.row)"
                            >分享</el-button
                          >
                        </el-form-item>
                      </el-form>
                    </div>
                    <el-button
                      style="margin-left: 10px"
                      type="text"
                      class="disib orange"
                      size="mini"
                      slot="reference"
                      >分享</el-button
                    >
                  </el-popover>
                  <el-button
                    type="text"
                    class="disib red"
                    size="mini"
                    style="margin-left: 10px"
                    @click="handleTagDelete(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-form>
        </div>
        <div class="df" style="justify-content: flex-end">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="tagTotal"
            :current-page="tagQuery.page"
            @current-change="tagPageChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="共享申请列表"
      :visible.sync="shareDialogVisible"
      width="50%"
    ></el-dialog>

    <el-dialog
      title="首页推荐管理"
      :visible.sync="showRecommend"
      width="50%"
      @open="handleOpen"
      @close="
        isSort = false;
        sortIndex = null;
      "
    >
      <el-table border :data="recommendList" style="width: 100%">
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="100">
        </el-table-column>
        <el-table-column prop="digest" label="摘要" width="120">
        </el-table-column>
        <el-table-column prop="coverImg" label="封面" width="120">
          <template slot-scope="scope">
            <el-image
              fit="cover"
              class="avatar-small"
              :preview-src-list="[scope.row.coverImg]"
              v-if="scope.row.coverImg"
              :src="scope.row.coverImg"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="types" label="标签" width="120">
          <template slot-scope="scope">
            <el-tag
              style="margin-right: 10px; margin-bottom: 10px"
              type="info"
              v-for="(item, index) in scope.row.types"
              :key="index"
              >{{ item }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib"
              size="mini"
              @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              class="disib orange"
              size="mini"
              style="margin-left: 10px"
              @click="
                isSort = true;
                sortIndex = scope.$index;
              "
              v-if="!isSort"
              >排序</el-button
            >
            <el-button
              type="text"
              class="disib green"
              size="mini"
              style="margin-left: 10px"
              @click="handleSort(scope.row)"
              v-if="isSort && scope.$index !== sortIndex"
              >交换</el-button
            >
            <el-button
              type="text"
              class="disib red"
              size="mini"
              style="margin-left: 10px"
              @click="
                isSort = false;
                sortIndex = null;
              "
              v-if="isSort && scope.$index === sortIndex"
              >取消</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="标签内文章" :visible.sync="showConsultList" width="50%">
      <div>
        <el-form
          :inline="true"
          :model="query"
          class="demo-form-inline"
          style="flex: 1"
        >
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-input
                placeholder="请输入文章标题、文章内容查询"
                v-model="previewQuery.keyword"
                class="input-with-select"
                clearable
                @clear="search"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6" class="tl mr10">
            <el-form-item size="medium">
              <el-button
                type="primary"
                @click="previewSearch"
                class="general_bgc general_border cfff"
                >查询</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <el-table
        border
        :data="previewList"
        style="width: 100%"
        v-loading="isLoading"
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="title" label="标题" width="100">
          <template slot-scope="scope"> 《{{ scope.row.title }}》 </template>
        </el-table-column>
        <el-table-column prop="digest" label="摘要" width="120">
        </el-table-column>
        <el-table-column prop="digest" label="审核状态" width="120">
        </el-table-column>
        <el-table-column label="预览" width="80">
          <template slot-scope="scope">
            <span v-if="shieldList.includes(scope.row.id)" style="color: #bbb">
              已屏蔽
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              type="text"
              class="disib"
              size="mini"
              style="margin-left: 10px; color: #bbb !important"
              v-if="!shieldList.includes(scope.row.id)"
              @click="handleShield(scope.row, true)"
              >屏蔽资讯</el-button
            >
            <el-button
              type="text"
              class="disib"
              size="mini"
              style="margin-left: 10px; color: #bbb !important"
              v-else
              @click="handleShield(scope.row, false)"
              >取消屏蔽</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="df" style="justify-content: flex-end">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="previewTotal"
          :current-page="previewQuery.page"
          @current-change="previewPageChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showPreviewDetail" width="380px">
      <div class="df jcc" style="max-height: 1200px; overflow-y: scroll">
        <div
          v-if="previewDetail"
          style="
            width:340px
            background-color: #fff;
            padding: 15px;
            min-height: 844px;
          "
        >
          <div
            class="df aic"
            style="font-size: 24px; font-weight: 600; padding: 10px"
          >
            {{ previewDetail.title }}
          </div>
          <div style="df jcc aic">
            <el-tag
              type="info"
              style="margin-right: 10px; margin-bottom: 10px"
              v-for="item in previewDetail.tagNames"
              :key="item"
              size="mini"
              >{{ item }}</el-tag
            >
          </div>
          <div
            style="
              color: #bbb;
              text-align: left;
              padding: 0px 15px;
              border: 1px solid #0081ff;
              padding: 15px;
              margin-bottom: 15px;
            "
            class="df aic"
          >
            {{ previewDetail.digest }}
          </div>
          <div
            v-html="previewDetail.content"
            style=""
            class="preview-box"
          ></div>
        </div>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="showShareCompany" width="50%" title="分享企业">
      <el-table
        border
        :data="shareCompany"
        style="width: 100%"
        v-if="shareCompany.length || !shareCompanyTotal"
      >
        <el-table-column type="index" label="序号" width="55">
        </el-table-column>
        <el-table-column prop="sharedCompanyName" label="企业名称" width="180">
        </el-table-column>
        <el-table-column type="isAudit" label="状态" width="180">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              v-model="scope.row.isAudit"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="需要审核"
              inactive-text="无需审核"
              @change="(val) => changeAudit(val, scope.row)"
            ></el-switch>
            <!-- {{ scope.row.isAudit ? "需要审核" : "无需审核" }} -->
          </template>
        </el-table-column>
      </el-table>
      <div class="df" style="justify-content: flex-end">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="shareCompanyTotal"
          :current-page="shareCompanyQuery.page"
          @current-change="companyPageChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showShare" width="50%" title="分享记录">
      <el-form
        :inline="true"
        :model="query"
        class="demo-form-inline"
        style="flex: 1"
      >
        <el-col :span="4" class="mr10">
          <el-form-item style="width: 100%; padding-left: 20px">
            <el-autocomplete
              placeholder="企业名称"
              v-model="shareQuery.keyword"
              :fetch-suggestions="querySearchAsync"
              clearable
              @select="handleSelect2"
              @clear="
                shareQuery.keyword = '';
                shareSearch();
              "
            >
              <template slot-scope="{ item }">
                <span class="name">{{ item.company_name }}</span>
                <span
                  class="addr"
                  style="font-size: 12px; color: silver; float: right"
                  >{{ item.credit_code }}</span
                >
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="6" class="tl mr10">
          <el-form-item size="medium">
            <el-button
              type="primary"
              @click="search"
              class="general_bgc general_border cfff"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-form>
      <div>
        <el-table border :data="share" style="width: 100%">
          <el-table-column type="index" label="序号" width="55">
          </el-table-column>

          <el-table-column prop="companyName" label="分享去向" width="100">
          </el-table-column>

          <el-table-column label="分享内容" width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.type == 1">
                {{ "《" + scope.row.sharedName + "》" }}
              </div>
              <div v-else>
                <el-tag type="info">{{ scope.row.sharedName }}</el-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="shareUserName" label="分享人" width="80">
          </el-table-column>

          <el-table-column prop="sharedTime" label="分享时间" width="100">
            <template slot-scope="scope">
              {{ scope.row.sharedTime.replace("T", " ") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="disib red"
                size="mini"
                style="margin-left: 10px"
                @click="cancelShare(scope.row)"
                >取消分享</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="df" style="justify-content: flex-end">
        <el-pagination
          background
          :page-size="10"
          layout="total,prev, pager, next"
          :total="shareTotal"
          :current-page="shareQuery.page"
          @current-change="sharePageChange"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import axios from "../../../util/api";
import api from "../../../util/extra-api";
import { local } from "@/util/util";
import AddTag from "./components/AddTag.vue";
import axios from "../../../util/api";

export default {
  components: { AddTag },
  data() {
    return {
      query: {
        keyword: "",
        page: 1,
        limit: 10,
        companyId: null,
      },
      tagQuery: {
        keyword: "",
        page: 1,
        limit: 10,
        companyId: null,
      },
      sortIndex: null,
      showRecommend: false,
      recommendList: [],
      isSort: false,
      list: [],
      total: 0,
      dialogVisible: false,
      tagList: [],
      tagTotal: 0,
      showTagList: false,
      tagKeyword: [],
      tagKeywordList: [],
      rules: {
        tagName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
        ],
      },
      chooseIndex: null,
      isShare: false,
      shareDialogVisible: false,
      formInline: {
        shareCompanyId: null,
        shareCompanyName: "",
      },
      shareRule: {
        shareCompanyName: [
          { required: true, message: "请选择要分享的企业", trigger: "blur" },
        ],
      },
      typeText: ["标签", "资讯"],
      passStatus: [
        {
          text: "审核中",
          class: "orange",
        },
        {
          text: "审核通过",
          class: "green",
        },
        {
          text: "审核未通过",
          class: "red",
        },
      ],
      isLoading: false,
      showConsultList: false,
      previewQuery: {
        page: 1,
        limit: 10,
        companyId: null,
        typeId: "",
        keyword: "",
      },
      previewList: [],
      previewTotal: 0,
      previewDetail: null,
      showPreviewDetail: false,
      showShare: false,
      shareQuery: {
        keyword: "",
        page: 1,
        limit: 10,
        companyId: null,
        type: 1,
      },
      share: [],
      shareTotal: 0,
      showShareCompany: false,
      shareCompany: [],
      shareCompanyTotal: 0,
      shareCompanyQuery: {
        page: 1,
        limit: 10,
        companyId: null,
      },
      shield: null,
      shieldList: [],
      options: [
        "全部",
        "无需审核",
        "已屏蔽",
        "审核中",
        "审核通过",
        "审核未通过",
      ],
      shareStatus: 0, //分享接收列表状态
    };
  },
  computed: {
    tagComputed() {
      return this.tagKeywordList.filter((v) => !this.tagKeyword.includes(v));
    },
    tagId() {
      let id = "";
      this.tagKeyword.forEach((v, i) => {
        id += v.id;
        if (i !== this.tagKeyword.length - 1) {
          id += ",";
        }
      });
      return id;
    },
  },
  mounted() {
    this.query.companyId = local.get("company_id");
    this.tagQuery.companyId = local.get("company_id");
    this.getTagKeyword();
    this.getList();
  },
  methods: {
    handleShield(row, parmas) {
      if (parmas) {
        api
          .post("/v1/wx/article/pc/shield", {
            companyId: local.get("company_id"),
            articleId: row.id,
            id: this.shield,
            type: 0,
          })
          .then((res) => {
            if (res.code === 200) {
              this.showConsultList = false;
              this.shieldList = [];
              this.shield = null;
              this.getShareList();
            }
          });
      } else {
        api
          .post("/v1/wx/article/pc/cancelShield", {
            companyId: local.get("company_id"),
            articleId: row.id,
            id: this.shield,
            type: 0,
          })
          .then((res) => {
            if (res.code === 200) {
              this.showConsultList = false;
              this.shieldList = [];
              this.shield = null;
              this.getShareList();
            }
          });
      }
    },
    optionChange(val) {
      // this.shareStatus = val;
      this.search();
    },
    changeAudit(val, row) {
      api
        .post("/v1/wx/article/pc/updateCompanyAudit", {
          sharedCompanyId: row.sharedCompanyId,
          isAudit: val,
          companyId: local.get("company_id"),
        })
        .then((res) => {
          if (res.code === 200) {
            this.getshareCompany();
          }
        });
    },
    getshareCompany(params) {
      if (!this.shareCompanyQuery.companyId) {
        this.shareCompanyQuery.companyId = local.get("company_id");
      }
      let data = {
        ...this.shareCompanyQuery,
      };
      if (params) {
        data.typeId = params;
      }
      api.get("/v1/wx/article/pc/companyList", data).then((res) => {
        if (res.code === 200) {
          this.shareCompany = res.data;
          this.shareCompanyTotal = res.total;
        }
      });
    },
    getShare() {
      if (!this.shareQuery.companyId) {
        this.shareQuery.companyId = local.get("company_id");
      }
      api.get("/v1/wx/article/pc/shareList", this.shareQuery).then((res) => {
        if (res.code === 200) {
          this.share = res.data;
          this.shareTotal = res.total;
        }
      });
    },
    handleAudit(row, params) {
      let data = {
        companyId: row.companyId,
        id: row.id,
      };
      if (typeof params === "boolean") {
        data.isDel = params;
      } else {
        data.isPass = params;
      }
      api.post("/v1/wx/article/pc/audit", data).then((res) => {
        if (res.code === 200) {
          this.getShareList();
        }
      });
    },
    handlePreview(row, type) {
      if (type) {
        this.shield = row.id;
        this.shieldList = JSON.parse(row.shieldArticles);
        this.previewQuery.companyId = row.sharedCompanyId;
        this.previewQuery.typeId = row.sharedId;
        this.showConsultList = true;
        this.getSharePreviewList();
      } else {
        api
          .get("/v1/wx/article/pc/articleDetail", { id: row.id })
          .then((res) => {
            this.previewDetail = res.data;
            this.showPreviewDetail = true;
          });
      }
    },
    handleShare(id, type, row) {
      this.$refs["shareForm"].validate((valid) => {
        if (valid) {
          let _this = this;
          api
            .post("/v1/wx/article/pc/share", {
              companyId: _this.formInline.shareCompanyId,
              companyName: _this.formInline.shareCompanyName,
              sharedCompanyId: _this.query.companyId,
              type,
              sharedId: id,
            })
            .then((res) => {
              if (res.code === 200) {
                row.isShare = false;
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    switchChange(data) {
      this.query.page = 1;
      this.tagKeyword = [];
      this.keyword = "";
      this.list = [];
      if (data) {
        this.getShareList();
      } else {
        this.getList();
      }
    },
    handleSelect(item) {
      this.formInline.shareCompanyId = item.company_id;
      this.formInline.shareCompanyName = item.company_name;
    },
    handleSelect1(item) {
      this.query.keyword = item.company_name;
    },
    handleSelect2(item) {
      this.shareQuery.keyword = item.company_name;
    },
    cancelShare(row) {
      api.post("/v1/wx/article/pc/cancelShare", { id: row.id }).then((res) => {
        if (res.code === 200) {
          if (this.share.length === 1 && this.shareQuery.page !== 1) {
            this.shareQuery.page--;
          }
          this.getShare();
        }
      });
    },
    querySearchAsync(queryString, cb) {
      if (queryString) {
        axios
          .get("/pc/company/signList/2", { company_name: queryString })
          .then((response) => {
            let results = response.data.company_list;
            cb(results);
          });
      } else {
        cb([]);
      }
    },
    getShareList() {
      this.isLoading = true;
      api
        .get("/v1/wx/article/pc/shareList", {
          ...this.query,
          ...[
            null,
            {
              isAudit: false,
              isPass: null,
              isDel: false,
            },
            {
              isAudit: null,
              isPass: null,
              isDel: true,
            },
            {
              isAudit: true,
              isPass: 0,
              isDel: false,
            },
            {
              isAudit: true,
              isPass: 1,
              isDel: false,
            },
            {
              isAudit: true,
              isPass: 2,
              isDel: false,
            },
          ][this.shareStatus],
        })
        .then((res) => {
          if (res.code === 200) {
            this.list = res.data;
            this.total = res.total;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    handleSort(row) {
      // console.log(row);
      let sort = row.sort;
      row.sort = this.recommendList[this.sortIndex].sort;
      this.recommendList[this.sortIndex].sort = sort;
      let list = this.recommendList.map((v) => {
        return {
          sort: v.sort,
          id: v.id,
        };
      });
      api.post("/v1/wx/article/sort", { list }).then((res) => {
        if (res.code === 200) {
          this.isSort = false;
          this.sortIndex = null;
          this.handleOpen();
        }
      });
    },
    handleOpen() {
      api
        .get("/v1/wx/article/pc/list", {
          page: 1,
          limte: 10,
          companyId: local.get("company_id"),
          recommend: true,
          keyword: "",
          typeId: "",
        })
        .then((res) => {
          if (res.code === 200) {
            this.recommendList = res.data;
          }
        });
    },
    addTag(item) {
      this.tagKeyword.push(item);
    },
    delTag(item) {
      this.tagKeyword = this.tagKeyword.filter((v) => v !== item);
    },
    getTagKeyword() {
      api
        .get("/v1/wx/article/pc/selectTag", {
          companyId: this.tagQuery.companyId,
        })
        .then((res) => {
          if (res.code == 200) {
            this.tagKeywordList = res.data;
          }
        });
    },
    gotoCreate() {
      this.$router.push({ path: "/admin/consult-detail" });
    },
    getList() {
      this.isLoading = true;
      api
        .get("/v1/wx/article/pc/list", { ...this.query, typeId: this.tagId })
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.map((v) => {
              v.isShare = false;
              return v;
            });
            this.total = res.total;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    getSharePreviewList() {
      api
        .get("/v1/wx/article/pc/list", this.previewQuery)
        .then((res) => {
          if (res.code == 200) {
            this.previewList = res.data;
            this.previewTotal = res.total;
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    search() {
      this.query.page = 1;
      if (this.isShare) {
        this.getShareList();
      } else {
        this.getList();
      }
    },
    shareSearch() {
      this.query.page = 2;
      this.getShare();
    },
    previewSearch() {
      this.previewQuery.page = 1;
      this.getSharePreviewList();
    },
    pageChange(page) {
      this.query.page = page;
      if (this.isShare) {
        this.getShareList();
      } else {
        this.getList();
      }
    },
    handleEdit(row) {
      this.$router.push({
        path: "/admin/consult-detail",
        query: { id: row.id },
      });
    },
    handleDelete(row) {
      api
        .post("/v1/wx/article/pc/deleteArticle", { id: row.id })
        .then((res) => {
          if (res.code == 200) {
            if (this.list.length === 1 && this.query.page !== 1) {
              this.query.page--;
            }
            this.getList();
          }
        });
    },
    handleTagEdit(row) {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let _this = this;
          api
            .post("/v1/wx/article/pc/updateTag", {
              id: row.id,
              tagDesc: row.tagDesc,
              tagName: row.tagName,
              companyId: this.tagQuery.companyId,
            })
            .then((res) => {
              if (res.code === 200) {
                _this.chooseIndex = null;
                row.isEdit = false;
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleTagDelete(row) {
      api
        .post("/v1/wx/article/pc/deleteTag", {
          id: row.id,
        })
        .then((res) => {
          if (this.tagList.length === 1 && this.tagQuery.page != 1) {
            this.tagQuery.page--;
          }
          this.getTagList();
        });
    },
    getTagList() {
      api.get("/v1/wx/article/pc/tagList", this.tagQuery).then((res) => {
        if (res.code === 200) {
          this.tagList = res.data.map((v) => {
            v.isEdit = false;
            v.isShare = false;
            return v;
          });
          this.tagTotal = res.total;
        }
      });
    },
    tagPageChange(page) {
      this.tagQuery.page = page;
      this.getTagList();
    },
    previewPageChange(page) {
      this.previewQuery.page = page;
      this.getSharePreviewList();
    },
    sharePageChange(page) {
      this.shareQuery.page = page;
      this.getShare();
    },
    companyPageChange(page) {
      this.shareCompanyQuery.page = page;
      this.getshareCompany();
    },
    tagSearch() {
      this.tagQuery.page = 1;
      this.getTagList();
    },
    handleClose() {
      this.tagQuery.keyword = "";
    },
    addFinish() {
      this.getTagList();
    },
  },
};
</script>

<style lang="scss" scoped>
.jcb {
  justify-content: space-between;
}
.avatar {
  width: 418px;
  height: 178px;
  display: block;
}

.avatar-small {
  width: 209px;
  height: 69px;
  display: block;
}

.tag-select-box {
  font-size: 18px;
  cursor: pointer;
  margin: 4px 0;
  padding: 4px 10px;
}
.tag-select-box:hover {
  background-color: #409eff;
  color: #fff;
}
.green {
  color: #67c23a;
}
/deep/ .el-table__empty-block {
  width: 100% !important;
}
/deep/ .el-autocomplete {
  width: 100% !important;
  height: 36px;
}
</style>
