<template>
  <div>
    <el-popover
      placement="right"
      width="400"
      v-model="show"
      trigger="click"
      @hide="closeDialog"
    >
      <div>
        <div style="padding: 10px; width: 80%">
          <el-form
            ref="ruleForm"
            :model="formData"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item label="标签名称" prop="tagName">
              <el-input v-model="formData.tagName" :maxlength="20">
                <span slot="suffix" style="margin-right: 10px"
                  >{{ formData.tagName.length }}/20</span
                >
              </el-input>
            </el-form-item>
            <el-form-item label="标签描述" prop="tagDesc">
              <el-input type="textarea" v-model="formData.tagDesc"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submit">确 定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <el-button type="primary" slot="reference">新增标签</el-button>
    </el-popover>

    <!-- <el-dialog
      title="新增标签"
      :visible.sync="show"
      @close="closeDialog"
      width="600px;"
    >
      <div style="padding: 10px; width: 80%">
        <el-form
          ref="ruleForm"
          :model="formData"
          label-width="80px"
          :rules="rules"
        >
          <el-form-item label="标签名称" prop="tagName">
            <el-input v-model="formData.tagName" :maxlength="20">
              <span slot="suffix" style="margin-right: 10px"
                >{{ formData.tagName.length }}/20</span
              >
            </el-input>
          </el-form-item>
          <el-form-item label="标签描述" prop="tagDesc">
            <el-input type="textarea" v-model="formData.tagDesc"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { local } from "@/util/util";
import api from "@/util/extra-api";

export default {
  data() {
    return {
      show: false,
      companyId: null,
      formData: {
        tagName: "",
        tagDesc: "",
      },
      rules: {
        tagName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.companyId = localStorage.getItem("company_id");
  },
  methods: {
    closeDialog() {
      this.formData.tagName = "";
      this.formData.tagDesc = "";
      this.$refs["ruleForm"].clearValidate();
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let _this = this;
          api
            .post("/v1/wx/article/pc/addTag", {
              ...this.formData,
              companyId: this.companyId,
            })
            .then((res) => {
              if (res.code === 200) {
                _this.show = false;
                _this.$emit("addFinish");
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form-item .el-form-item {
  margin-bottom: 22px !important;
}
</style>